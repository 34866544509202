// @formatter:off
export const Privacy20231128 = {
    date: "20231128",
    name: "2023년 11월 28일 시행",
    pages: [
        {
            "name": "개인정보의 처리 목적",
            "element": <>
                눈쟁전자는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」
                제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
                <br/>
                <br/>
                1. 홈페이지 회원가입 및 관리
                <br/>
                회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, 각종 고지·통지, 고충처리 목적으로 개인정보를 처리합니다.
                <br/>
                <br/>
                2. 민원사무 처리
                <br/>
                민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 목적으로 개인정보를 처리합니다.
                <br/>
                <br/>
                3. 재화 또는 서비스 제공
                <br/>
                물품배송, 서비스 제공, 계약서·청구서 발송, 콘텐츠 제공, 맞춤서비스 제공, 본인인증, 연령인증, 요금결제·정산, 채권추심을 목적으로 개인정보를 처리합니다.
                <br/>
                <br/>
                4. 마케팅 및 광고에의 활용
                <br/>
                신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 , 인구통계학적 특성에 따른 서비스 제공 및 광고 게재 , 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.
            </>
        },
        {
            "name": "개인정보의 처리 및 보유 기간",
            "element": <>
                ① 눈쟁전자는 정보주체로부터 개인정보를 수집할 때 동의 받은 개인정보 보유․이용기간 또는 법령에 따른 개인정보
                보유․이용기간 내에서 개인정보를 처리․보유합니다.
                <br/>
                ② 구체적인 개인정보 처리 및 보유 기간은 다음과 같습니다.
                <br/>
                <br/>
                - 디스코드 봇 서비스 운영 : 서비스 제공 완료시까지 보관
                <br/>
                - 디스코드 봇 전용 사이트 운영 : 서비스 제공 완료시까지 보관
            </>
        },
        {
            "name": "처리하는 개인정보의 항목",
            "element": <>
                ① 눈쟁전자는 다음의 개인정보 항목을 처리하고 있습니다.
                <br/>
                <br/>
                1. 디스코드 봇 서비스 운영
                <br/>
                필수 항목: 디스코드 계정 식별자, 디스코드 계정 프로필 사진, 디스코드 계정 이름, 디스코드 서버 식별자, 디스코드 서버 이름, 디스코드 채널 식별자, 디스코드 채널 이름, 디스코드
                작성 메시지, 디스코드 메시지에 첨부된 파일, 디스코드 메시지 작성 일자, 디스코드 메시지 수정 일자, 디스코드 메시지 삭제 일자
                <br/>
                선택 항목: 디스코드 메시지 반응, 디스코드 봇이 보낸 버튼 클릭
                <br/>
                <br/>
                2. 디스코드 봇 전용 사이트 운영
                <br/>
                필수 항목: 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 라이엇게임즈 계정 식별자, 라이엇게임즈 계정 닉네임, 라이엇게임즈의 게임을 실행하여 활동한 게임의 랭크 정보, 디스코드 계정 식별자, 디스코드 계정 프로필 사진, 디스코드 계정 이름
                <br/>
                선택 항목:
                <br/><br/>
                3. Google Analytics
                <br/>
                - 눈쟁전자는 서비스 이용 및 통계 분석을 목적으로 Google LLC 가 제공하는 Google Analytics 를 이용하고 있습니다.
                <br/>
                - 눈쟁전자는 Google Analytics 를 통해 개인을 식별할 수 있는 정보는 수집하지 않습니다.
                <br/>
                - Google 개인정보 처리방침 : https://policies.google.com/privacy?hl=ko
                <br/>
                - Google Analytics 서비스 약관 : https://marketingplatform.google.com/about/analytics/terms/kr/
                <br/>
                - Google Analytics 차단 브라우저 부가 기능 : https://tools.google.com/dlpage/gaoptout
                <br/><br/>
                4. Cloudflare Web Analytics
                <br/>
                - 눈쟁전자는 서비스 이용 및 통계 분석을 목적으로 Cloudflare, Inc 가 제공하는 Web Analytics 를 이용하고 있습니다.
                <br/>
                - 눈쟁전자는 Cloudflare Web Analytics 를 통해 개인을 식별할 수 있는 정보는 수집하지 않습니다.
                <br/>
                - Cloudflare Web Analytics 소개 : https://www.cloudflare.com/ko-kr/web-analytics/
                <br/>
                - Cloudflare 개인정보 처리방침 : https://www.cloudflare.com/ko-kr/privacypolicy/
                <br/>
            </>
        },
        {
            "name": "개인정보의 파기절차 및 파기방법",
            "element": <>
                ① 눈쟁전자는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
                <br/>
                ② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
                <br/>
                ③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
                <br/>
                <br/>
                1. 파기절차
                <br/>
                눈쟁전자는 파기 사유가 발생한 개인정보를 선정하고, 눈쟁전자의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
                <br/>
                <br/>
                2. 파기방법
                <br/>
                전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.
                <br/>
                종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다
            </>
        },
        {
            "name": "정보주체와 법정대리인의 권리·의무 및 행사방법에 관한 사항",
            "element": <>
                ① 정보주체는 눈쟁전자에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.
                <br/>
                ② 제1항에 따른 권리 행사는 눈쟁전자에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 전자우편을 통하여 하실 수 있으며 눈쟁전자는 이에 대해 지체 없이 조치하겠습니다.
                <br/>
                ③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
                <br/>
                ④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.
                <br/>
                ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
                <br/>
                ⑥ 눈쟁전자는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
            </>
        },
        {
            "name": "개인정보의 안전성 확보조치에 관한 사항",
            "element": <>
                눈쟁전자는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
                <br/>
                <br/>
                1. 해킹 등에 대비한 기술적 대책
                <br/>
                눈쟁전자는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.
                <br/>
                <br/>
                2. 개인정보의 암호화
                <br/>
                이용자의 개인정보는 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.
            </>
        },
        {
            "name": "개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항",
            "element": <>
                ① 눈쟁전자는 이용자에게 서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다.
                <br/>
                ② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.
                <br/>
                가. 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 로그인 여부 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.
                <br/>
                나. 쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.
                <br/>
                다. 쿠키 저장을 거부할 경우 서비스 이용이 불가할 수 있습니다.
            </>
        },
        {
            "name": "개인정보 보호책임자에 관한 사항",
            "element": <>
                ① 눈쟁전자는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
                <br/>
                <br/>
                ▶ 개인정보 보호책임자
                <br/>
                성명 : 박희준
                <br/>
                연락처 : privacy@eyejoker.com
                <br/>
                <br/>
                ※ 개인정보 보호 담당부서로 연결됩니다.
                <br/>
                ▶ 개인정보 보호 담당부서
                <br/>
                부서명 : 대표이사
                <br/>
                담당자 : 박희준
                <br/>
                연락처 : privacy@eyejoker.com
                <br/>
                <br/>
                ② 정보주체께서는 눈쟁전자 의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 눈쟁전자는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
            </>
        },
        {
            "name": "개인정보의 열람청구를 접수·처리하는 부서",
            "element": <>
                정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다.
                눈쟁전자는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
                <br/>
                <br/>
                ▶ 개인정보 열람청구 접수·처리 부서
                <br/>
                부서명 : 대표이사
                <br/>
                담당자 : 박희준
                <br/>
                연락처 : privacy@eyejoker.com
            </>
        },
        {
            "name": "정보주체의 권익침해에 대한 구제방법",
            "element": <>
                정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
                <br/>
                <br/>
                1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)
                <br/>
                2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
                <br/>
                3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)
                <br/>
                4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)
                <br/>
                <br/>
                「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.
                <br/>
                <br/>
                ※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.
            </>
        },
        {
            "name": "개인정보 처리방침 변경",
            "element": <>
                ① 이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.
                <br/>
                ② 이전의 개인정보 처리방침은 우측 상단 메뉴에서 확인하실 수 있습니다.
            </>
        }
    ]
}
// @formatter:on
