import React, {Component} from 'react';
import {Route, Routes} from 'react-router-dom';
import {Navigation} from "./Navigation";
import Privacy from "../pages/Privacy";
import Tos from "../pages/Tos";

class App extends Component {
    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <main className="main-content col-lg-12 col-md-12 col-sm-12 p-0">
                        <Navigation/>
                        <div className="main-content-container container px-4 pt-3">
                            <Routes>
                                <Route path="/privacy" element={<Privacy/>}/>
                                <Route path="/tos" element={<Tos/>}/>
                            </Routes>
                        </div>
                    </main>
                </div>
            </div>
        );
    }
}

export default App;
