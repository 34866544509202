// @formatter:off
export const Tos20231128 = {
    date: "20231128",
    name: "2023년 11월 28일 시행",
    pages: [
        {
            "name": "서비스의 목적에 관한 사항",
            "element": <>
                본 약관은 눈쟁전자(이하 '서비스')의 이용 조건 및 절차, 이용자와 서비스 제공자 간의 권리, 의무 및 책임 사항을 규정함을 목적으로 합니다.
            </>
        },
        {
            "name": "용어의 정의에 관한 사항",
            "element": <>
                본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
                <br/>
                <br/>
                - "이용자"란 서비스에 접속하여 본 약관에 따라 서비스를 이용하는 회원 및 비회원을 말합니다.
                <br/>
                - "회원"이란 서비스에 개인정보를 제공하여 회원등록을 한 자로서, 서비스의 정보를 지속적으로 제공받으며, 서비스가 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다.
                <br/>
                - "비회원"이란 회원에 가입하지 않고 서비스가 제공하는 서비스를 이용하는 자를 말합니다.
            </>
        },
        {
            "name": "서비스 이용 계약의 성립에 관한 사항",
            "element": <>
                이용 계약은 이용자가 본 약관의 내용에 동의하고 회원가입 신청을 하며, 서비스가 이를 승낙함으로써 성립합니다.
                <br/>
                서비스는 만 14세 미만의 아동의 회원가입을 제한할 수 있습니다.
            </>
        },
        {
            "name": "회원의 의무에 관한 사항",
            "element": <>
                회원은 본 약관에서 규정하는 사항과 기타 서비스가 정한 제반 규정, 공지사항 등 서비스가 고지하는 사항을 준수하여야 합니다.
                <br/>
                회원은 서비스의 운영에 방해가 되는 행위를 하여서는 안 됩니다.
            </>
        },
        {
            "name": "서비스 제공에 관한 사항",
            "element": <>
                서비스는 회원에게 다음과 같은 서비스를 제공합니다.
                <br/>
                - 디스코드 봇 서비스 운영
                <br/>
                - 디스코드 봇 전용 사이트 운영
                <br/>
                - 기타 서비스가 자체적으로 개발하거나 다른 회사와의 협력을 통해 제공하는 서비스
                <br/>
                <br/>
                서비스는 필요한 경우 서비스의 내용을 변경할 수 있으며, 변경된 서비스의 내용 및 제공일자를 약관 변경절차에 따라 공지합니다.
            </>
        },
        {
            "name": "개인정보 처리 및 보호에 관한 사항",
            "element": <>
                서비스는 이용자의 개인정보를 보호하고 존중합니다. 서비스는 관련 법령 및 개인정보 처리방침에 따라 이용자의 개인정보를 안전하게 처리합니다.
                <br/>
                서비스의 개인정보 처리방침은 별도로 고지한 바에 따릅니다.
            </>
        },
        {
            "name": "저작권 및 지적 재산권에 관한 사항",
            "element": <>
                서비스가 제공하는 모든 콘텐츠(텍스트, 그래픽, 로고, 아이콘, 이미지, 오디오 클립, 디지털 다운로드, 데이터 컴파일레이션 등)의 저작권은 눈쟁전자에 속합니다.
                <br/>
                이용자는 서비스를 이용함으로써 얻은 정보 중 눈쟁전자 또는 제3자의 저작권 등 지적재산권에 속하는 정보를 눈쟁전자의 사전 동의 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리 목적으로 이용하거나 제3자에게 이용하게 해서는 안 됩니다.
            </>
        },
        {
            "name": "책임의 제한에 관한 사항",
            "element": <>
                눈쟁전자는 천재지변, 전쟁, 테러 행위, 폭동, 민란, 국가 비상사태, 서비스 공급자의 고의적 또는 과실적 행위 등 불가항력적 사건으로 인하여 서비스를 제공할 수 없을 경우 책임이 면제됩니다.
                <br/>
                눈쟁전자는 이용자의 고의 또는 과실로 인한 서비스 이용 장애에 대해서는 책임을 지지 않습니다.
                <br/>
                눈쟁전자는 이용자 간 또는 이용자와 제3자 상호 간에 서비스를 매개로 발생한 분쟁에 대하여 책임을 지지 않습니다.
            </>
        },
        {
            "name": "분쟁 해결 및 관할 법원에 관한 사항",
            "element": <>
                서비스 이용과 관련하여 눈쟁전자와 이용자 간에 발생한 분쟁은 대한민국 법률을 적용하여 해결합니다.
                <br/>
                서비스 이용 중 발생한 분쟁에 대해 소송이 제기될 경우, 눈쟁전자의 본사 소재지를 관할하는 법원을 관할 법원으로 합니다.
            </>
        },
        {
            "name": "약관의 변경에 관한 사항",
            "element": <>
                눈쟁전자는 약관이 변경될 경우, 변경 사항을 서비스 공지사항을 통해 공지합니다.
                <br/>
                변경된 약관은 공지된 시점부터 효력이 발생하며, 이용자는 변경된 약관에 동의하지 않을 경우 서비스 이용을 중단하고 회원 탈퇴를 할 수 있습니다.
            </>
        },
        {
            "name": "기타 조항에 관한 사항",
            "element": <>
                서비스는 필요한 경우 특정 서비스나 기능의 이용을 제한할 수 있으며, 이에 대한 사전 통지를 할 수 있습니다.
                <br/>
                서비스는 이용자가 본 약관의 규정을 위반하거나 서비스의 정상적인 운영을 방해한 경우, 서비스 이용을 제한하거나 중지시킬 수 있습니다.
                <br/>
                본 약관은 서비스를 이용하는 모든 이용자에게 적용됩니다.
            </>
        }
    ]
}
// @formatter:on
