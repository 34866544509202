import React, {Component} from "react";
import {Link} from 'react-router-dom';
import {Navbar} from 'reactstrap';

export class Navigation extends Component {
    render() {
        return (
            <div>
                <Navbar dark color="dark" expand="md">
                    <Link to="/" className="navbar-brand" style={{textDecoration: 'none'}}>
                        눈쟁전자 고객 서비스 포털
                    </Link>
                </Navbar>
            </div>
        );
    }
}
