import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import App from '../shared/App';

const Root = () => (
    <BrowserRouter>
        <React.Suspense fallback={<h1>잠시만 기다려주세요...</h1>}>
            <App/>
        </React.Suspense>
    </BrowserRouter>
);

export default Root;
