import React, {createRef, useState} from 'react';
import {Col, Row} from "reactstrap";
import {Tos20231128} from "./pages/Tos20231128";
import {useLocation} from "react-router-dom";

const allData = [
    Tos20231128,
];

const Tos = () => {
    const location = useLocation();

    const [date, setDate] = useState('');
    const [pageRefs, setPageRefs] = React.useState([]);

    const hasDateInAllData = (date) => {
        return allData.map(data => data.date).includes(date);
    }

    const getPageData = () => {
        let pageData;
        if (date.length === 0) {
            pageData = allData[0];
        } else {
            const filtered = allData.filter(data => {
                return data.date === date;
            });

            if (filtered.length === 0) {
                setDate('');
                pageData = allData[0];
            } else {
                pageData = filtered[0];
            }
        }

        return pageData;
    }

    const handleChangeDate = (e) => {
        setDate(e.target.value);
    }

    // 페이지 로드
    React.useEffect(() => {
        const inputDate = location.hash.replace('#', '');
        if (hasDateInAllData(inputDate)) {
            setDate(inputDate);
        } else {
            setDate(allData[0].date);
        }
    }, [location.hash]);

    // 선택한 일자를 hash 에 추가
    React.useEffect(() => {
        window.history.pushState({date: date}, '', `/tos#${date}`);
    }, [date]);

    const pageData = getPageData();

    // Ref Array 등록
    React.useEffect(() => {
        setPageRefs(pageRefs => (
            Array(pageData.pages.length).fill().map((_, i) => pageRefs[i] || createRef())
        ));
    }, [pageData.pages.length]);

    if (date.length === 0) return null;
    return (
        <div>
            <Row>
                <Col sm={6} md={8}>
                    <h3>서비스 이용약관</h3>
                </Col>
                <Col sm={6} md={4}>
                    <select name="select" className="form-control" value={date} onChange={handleChangeDate}>
                        {allData.map(data => (
                            <option key={data.date} value={data.date}>{data.name}</option>
                        ))}
                    </select>
                </Col>
            </Row>
            <hr style={{marginTop: '20px'}}/>
            <h5>목차 <span style={{fontSize: '10pt'}}>(클릭 시 해당 위치로 이동합니다)</span></h5>
            <ol style={{color: '#bfbfbf'}}>
                {pageData.pages.map((data, index) => (
                    <li key={`menu-${index}`} style={{marginTop: '10px', marginBottom: '10px'}}>
                        <b style={{cursor: 'pointer'}}
                           onClick={() => pageRefs[index].current.scrollIntoView()}>{data.name}</b>
                    </li>
                ))}
            </ol>
            <hr style={{marginBottom: '40px'}}/>
            {pageData.pages.map((data, index) => (
                <div ref={pageRefs[index]} key={`page-${index}`}>
                    <hr style={{borderTop: '2px solid white'}}/>
                    <h4>{index + 1}. {data.name}</h4>
                    <hr style={{borderTop: '2px solid white'}}/>
                    <p style={{marginBottom: '50px', color: '#9c9c9c'}}>{data.element}</p>
                </div>
            ))}
        </div>
    );
};

export default Tos;
